import React, { useState } from 'react';

import './YoutubeVideo.scss';
import ReactPlayer from 'react-player';

const YoutubeVideo = () => {
  const [youtubeLink, setYoutubeLink] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const changeYoutubeHandler = (e) => {
    setYoutubeLink(e.target.value);
  };

  const submitYoutubeHandler = (e) => {
    e.preventDefault();
    setSubmitted(true);
  };

  return (
    <div className='youtube'>
      {submitted && (
        <div className='player'>
          <ReactPlayer
            width={'200px'}
            height={'100px'}
            loop={true}
            controls={true}
            url={youtubeLink}
          />
          <button onClick={() => setSubmitted(false)}>
            Choose another video
          </button>
        </div>
      )}
      {!submitted && (
        <form onSubmit={submitYoutubeHandler} className='input'>
          <input
            value={youtubeLink}
            onChange={changeYoutubeHandler}
            placeholder='Youtube Music URL'
          />
          <button type='submit'>Submit</button>
        </form>
      )}
    </div>
  );
};

export default YoutubeVideo;
